import React, { useState, useEffect } from "react";
import ArrowDown from "../images/arrow_down.inline.svg";
import { Styling } from "../styles/pages/student-success.styled";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import Seo from "../components/global/seo";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Quotes from "../images/quotes.inline.svg";

export default function StudentSuccess({ data }) {
  // Array of all products
  const allProducts = data.allWpTestimonial.nodes;
  // //console.log(allProducts)

  function arraymove(arr, fromIndex, toIndex) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

  let zeroPostIndex = [];

  // Find index of post that MENU_ORDER is null i.e is 0
  allProducts.map((product, index) => {
    //console.log(product.title, product.menuOrder, index);
    if (product.menuOrder === null) {
      zeroPostIndex.push(index);
      product.menuOrder = index;
    }
  });

  // Reorder allProducts array and bring zero posts to start of array
  zeroPostIndex.map((index) => {
    arraymove(allProducts, index, 0);
  });

  // State for the list
  const [list, setList] = useState([...allProducts.slice(0, 12)]);

  // State to trigger load more
  const [loadMore, setLoadMore] = useState(false);

  // State of whether there is more to load
  const [hasMore, setHasMore] = useState(allProducts.length > 12);

  // Load more button click
  const handleLoadMore = () => {
    setLoadMore(true);
  };

  // Handle loading more articles
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length;
      const isMore = currentLength < allProducts.length;
      const nextResults = isMore
        ? allProducts.slice(currentLength, currentLength + 12)
        : [];
      setList([...list, ...nextResults]);
      setLoadMore(false);
    }
  }, [loadMore, hasMore]); //eslint-disable-line

  //Check if there is more
  useEffect(() => {
    const isMore = list.length < allProducts.length;
    setHasMore(isMore);
  }, [list]); //eslint-disable-line

  return (
    <Styling>
      <Seo
        title={data.wpPage.seo.title}
        description={data.wpPage.seo.metaDesc}
      />
      <div className="intro">
        <h2 className="title">
          <span>Student</span>{" "}
          <span>
            <ArrowDown className="arrow title-arrow" /> Success
          </span>
        </h2>
      </div>

      <div className="testimonials_wrapper">
        {list.map((testimonial, index) => {
          const theImage = getImage(
            testimonial?.testimonialFields?.profileImage?.localFile
          );
          return (
            <Link
              to={`/student-success/${testimonial.slug}`}
              className="testimonial"
              key={index + "_" + testimonial.slug}
            >
              <div className="image-wrapper">
                <GatsbyImage image={theImage} className="image" alt={"test"} />
                <div className="readMore">Read more</div>
              </div>
              <div className="testimony">
                <Quotes /> {testimonial.testimonialFields.archiveQuote}
              </div>
              <div className="name">&mdash; {testimonial.title}</div>
              <div className="course">
                {testimonial.testimonialFields.courseCompletedCustomOverride
                  ? testimonial.testimonialFields.courseCompletedCustomOverride
                  : testimonial.testimonialFields.courseCompleted.title}
              </div>
            </Link>
          );
        })}
      </div>
      {hasMore ? (
        <button
          className="capsule_button white loadMore"
          onClick={handleLoadMore}
        >
          Load More
        </button>
      ) : (
        ""
      )}
    </Styling>
  );
}

export const query = graphql`
  {
    wpPage(title: { eq: "Student Success" }) {
      id
      seo {
        metaDesc
        title
      }
    }
    allWpTestimonial(sort: { order: ASC, fields: menuOrder }) {
      nodes {
        title
        slug
        menuOrder
        testimonialFields {
          archiveQuote
          courseCompletedCustomOverride
          courseCompleted {
            ... on WpCourse {
              title
            }
            ... on WpProgramme {
              title
            }
          }
          profileImage {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1500
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
  }
`;
