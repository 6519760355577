import styled from 'styled-components'

const Styling = styled.div`
width: 100%;
background: var(--white);
padding-bottom: 8rem;

    .loadMore{
    display: flex;
    position: relative;
    margin: 0 auto;
    }

    .intro{
    background:var(--white);
    padding:10rem 2rem 2rem 2rem;

        @media only screen and (min-width: 350px) {
        padding:10rem 4rem 4rem 4rem;
        }
 
        @media only screen and (min-width: 1024px) {
        padding:10rem 8rem 8rem 8rem;
        }
        @media only screen and (min-width: 1250px) {
        padding:16rem 8rem;
        }

        h2{
        ${({theme}) => theme.tomato('regular')}  
        text-transform:uppercase;
        position: relative;
        width: auto;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        font-size:3rem;
        line-height:3rem;


            @media only screen and (min-width: 350px) {
            font-size:4rem;
            line-height:4rem;
            letter-spacing: -0.2rem;
            }

            @media only screen and (min-width: 420px) {
            font-size:5rem;
            line-height:5rem;
            letter-spacing: -0.3rem;
            }
            
            @media only screen and (min-width: 610px) {
            font-size:6rem;
            line-height:6rem;
            letter-spacing: -0.4rem;
            }
            
            @media only screen and (min-width: 800px) {
            font-size:7rem;
            line-height:7rem;   
            letter-spacing: -0.04em;
            }
            
            @media only screen and (min-width: 1024px) {
            letter-spacing: -0.7rem;
            font-size:clamp(6rem, 8vw, 10rem);
            line-height: clamp(6rem/1.15, 8vw/1.15, 10rem/1.15);
            }
        
            span{
            width: 100%;
            align-items: center;
            display: flex;
            }

            .arrow{
            width: 0.7em;
            height: 0.7em;
            margin-right:1rem;

                @media only screen and (min-width: 1024px) {
                margin-right: 2rem;
                }

                path{
                fill:var(--black);
                }

            }
        }
    }

    .testimonials_wrapper{
    width: 100%;
    display: grid;
    grid-gap: 2rem;
    padding:0 2rem;

        @media only screen and (min-width: 350px) {
        padding:0 4rem;
        grid-template-columns: repeat(1, 1fr);
        }
        
        @media only screen and (min-width:600px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media only screen and (min-width: 1024px) {
        grid-template-columns: repeat(3, 1fr);
        }
        
        @media only screen and (min-width: 1550px) {
        grid-template-columns: repeat(4, 1fr);
        }

        a{
        text-decoration: none;
        color:inherit;
        margin-bottom: 4rem;
            

            .image-wrapper{
            position: relative;
            margin-bottom: 2rem;
            border-radius: 10px;
            overflow: hidden;

                .gatsby-image-wrapper{
                height: 0;
                padding-bottom: 100%;

                    img{
                    ${({theme}) => theme.easing('0.5')}  
                    }
                }
                
                .readMore{
                position: absolute;
                bottom:2rem;
                left:2rem;
                width: calc(100% - 4rem);
                padding:1rem;
                background:var(--white);
                border-radius: 2rem;
                z-index: 10;
                text-align:center;
                text-transform:uppercase;
                font-size:0.75em;
                opacity: 0;
                transform: translateY(2rem);
                ${({theme}) => theme.easing('0.5')}  
                }
            }

            .testimony{
            margin-bottom: 2rem;
            font-size:clamp(1.8rem, 1.7vw, 4rem);
            line-height:clamp(1.8rem*1.25, 1.7vw*1.25, 4rem*1.25);
            text-transform: uppercase;

                svg{
                    width:clamp(1.8rem, 1.7vw, 4rem);
                }

            }


            .name,
            .course{
                font-size:clamp(1.2rem, 1vw, 2rem);
                line-height:clamp(1.2rem*1.25, 1vw*1.25, 2rem*1.25);
            }

            &:hover{
                .image-wrapper{
                    .readMore{
                    opacity: 1;
                    transform:translateY(0);
                    }

                    img{
                    transform:rotate(-2deg) scale(1.2);
                    }
                }
            }
        }

    }
    
`

export { Styling }